<template>
  <!-- Hero -->
  <div class="max-w-[1320px] w-full mx-auto px-4 pt-12 md:pt-24">
    <p class="text-3xl md:text-4xl max-w-2xl mt-24 mb-10">
      Welcome to <span class="text-brand-green">ARK</span>, where innovation meets efficiency, and the skies are no longer the limit.
    </p>
  </div>

  <!-- We aim -->
  <div class="w-full bg-opacity-50 bg-black relative h-96 md:h-[480px] mb-12">
    <div class="max-w-[1320px] mx-auto px-4 flex items-end py-12 w-full h-full">
      <div class="text-white text-2xl md:text-3xl font-medium md:w-2/4">
        We aim to automate every aspect associated with delivery logistics, creating a cohesive system that helps solve challenges.
      </div>
    </div>

    <img src="../assets/4bg.png" class="absolute object-cover object-center -z-10 inset-0 h-96 md:h-[480px] w-full"/>
  </div>

  <!-- About us -->
  <div class="max-w-[1320px] w-full mx-auto px-4">
    <p class="mb-8">About us</p>
    <div class="w-full flex flex-col md:flex-row items-start gap-8 mb-16 md:mb-32">
      <div class="md:w-1/2 text-2xl font-medium">
        We specialize in providing comprehensive solutions that revolutionize the way we approach logistics and transportation.
      </div>

      <div class="md:w-1/2 text-lg">
        With a seamless integration of cutting-edge drone technology, a sophisticated cloud-based fleet management platform, and the establishment of mini-airports, we redefine the possibilities of aerial delivery and logistics management.
      </div>
    </div>

    <div class="flex flex-col md:flex-row items-center mb-24 gap-4">
      <div class="md:w-1/2">
        <img src="../assets/4b1.svg" alt="img">
      </div>

      <div class="md:w-1/2">
        <p class="text-2xl md:text-3xl font-medium mb-4">
          Our commitment to excellence drives us to craft drone couriers tailored to meet diverse needs,
          whether it's <span class="text-brand-green">delivering food, medications, or packages.</span>
        </p>

        <p class="md:text-lg">
          From ensuring timely medical deliveries to enhancing the efficiency of e-commerce logistics, our range of drones encompasses a spectrum of capabilities, including specialized models for medical emergencies, food delivery, and heavy lifting.
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row items-center gap-10 border-b pb-12 md:pb-24">
      <div class="order-last md:order-first md:w-1/2 md:pr-4">
        <p class="text-2xl md:text-3xl font-medium mb-4">
          Beyond offering state-of-the-art hardware solutions, we take pride in our holistic approach, providing comprehensive
          <span class="text-brand-green">training programs</span> to equip personnel with the skills necessary to leverage our technology effectively.
        </p>

        <p class="md:text-lg md:pr-4">
          Through continuous innovation and dedication to customer satisfaction, we strive to reshape the future of transportation, making it smarter, faster, and more sustainable.
        </p>
      </div>

      <div class="md:w-1/2 w-full flex justify-end">
        <img src="../assets/red2.png" alt="img" class="rounded-2xl md:w-[536px]">
      </div>
    </div>
  </div>

  <!-- Our mission -->
  <div class="max-w-[1320px] w-full mx-auto px-4 py-12 md:py-24 flex flex-col md:flex-row">
    <div class="md:w-2/3">
      <p class="text-2xl md:text-3xl font-medium mb-5">Our mission</p>
      <p class="md:text-2xl mb-10">
        is on a mission to make everyday life more fun, convenient, and secure — by creating the efficient logistics system and providing access to goods and products for everyone equally
      </p>

      <div class="mb-12">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 md:mb-16">
          <div class="w-52">
            <img src="../assets/sun.svg" class="mb-2">
            <p class="font-medium text-2xl md:text-3xl mb-3">Vision</p>
            <p>Make every aspect of logistics autonomous</p>
          </div>

          <div class="w-52">
            <img src="../assets/star.svg" class="mb-2">
            <p class="font-medium text-2xl md:text-3xl mb-3">Values</p>
            <p>Make every aspect of logistics autonomous</p>
          </div>

        </div>
        <div class="max-w-xl">
          <img src="../assets/goal.svg" class="mb-2">
          <p class="font-medium text-2xl md:text-3xl mb-3">Goals</p>
          <p>
            Our goal extends beyond achieving autonomy solely in delivery logistics; we aim to autonomize every facet associated with it, fostering a seamless system that alleviates concerns. Additionally, we aspire to innovate and provide superior solutions compared to current market offerings
          </p>
        </div>
      </div>
    </div>

    <div class="md:w-1/2 flex justify-end">
      <img src="../assets/paint.png"/>
    </div>
  </div>

  <div class="max-w-[1320px] w-full mx-auto px-4 gap-4 grid grid-cols-2 md:grid-cols-3 md:border-b pb-10">
    <div class="font-medium">
      <span class="text-4xl md:text-6xl mb-4">98%</span>
      <p class="text-sm md:text-lg">Percentage of on-time deliveries</p>
    </div>

    <div class="font-medium">
      <span class="text-4xl md:text-6xl mb-4">20%</span>
      <p class="text-sm md:text-lg">Average cost savings for customers</p>
    </div>

    <div class="font-medium">
      <span class="text-4xl md:text-6xl mb-4">1.25 million</span>
      <p class="md:text-lg">Total distance covered</p>
    </div>
  </div>

  <!-- Team -->
<!--
  <div class="max-w-[1320px] w-full mx-auto px-4 gap-8 py-12 md:py-24 flex flex-col md:flex-row items-center justify-between">
    <div class="order-last md:order-first md:w-1/2">
      <p class="text-3xl md:text-4xl font-medium mb-5">Team</p>
      <p class="md:text-2xl mb-10 max-w-lg">Experienced experts that are uniquely positioned to solve this
        problem</p>

      <p class="text-xl md:text-2xl text-brand-gray-secondary7 mb-8">Highlights</p>

      <div class="flex flex-col gap-4 md:gap-8 md:text-lg">
        <div class="flex items-center gap-3">
          <div class="rounded-full bg-brand-green h-2 w-2"></div>
          <p class="m-0 p-0">Flytrex, SpaceX and Tesla talents</p>
        </div>

        <div class="flex items-center gap-3">
          <div class="rounded-full bg-brand-green h-2 w-2"></div>
          <p>+28 years of experience combined</p>
        </div>

        <div class="flex items-center gap-3 w-full">
          <div class="rounded-full bg-brand-green h-2 w-2"></div>
          <p class="md:max-w-md w-full">
            Consultants and Advisors from
            Google, NASA,
            Walmart Head of Last-
            Mile and DoorDash Director of CX,
            CMO Blue Cross Blue Shield
          </p>
        </div>
      </div>
    </div>

    <div class="md:w-1/2 flex md:justify-end w-full">
      <div class="md:w-96 md:h-96 h-48 w-full rounded-2xl bg-gray-300"></div>
    </div>
  </div>
-->

  <!-- Testimonials -->
<!--  <div class="max-w-[1320px] w-full mx-auto px-4 py-12 md:py-24">
    <p class="text-2xl md:text-3xl mb-8">Founding team</p>

    <div class="grid grid-cols-2 md:grid-cols-3 md:flex gap-4 md:gap-6 text-center">
      <div>
        <div class="md:w-52 h-52 bg-gray-300 mb-3"></div>
        <p class="mb-1 md:text-2xl font-medium">Haonan Zhang</p>
        <p class="text-xs md:text-lg">CEO</p>
      </div>

      <div>
        <div class="md:w-52 h-52 bg-gray-300 mb-3"></div>
        <p class="mb-1 md:text-2xl font-medium">Alibek Yertay</p>
        <p class="text-xs md:text-lg">Software Development</p>
      </div>

      <div>
        <div class="md:w-52 h-52 bg-gray-300 mb-3"></div>
        <p class="mb-1 md:text-2xl font-medium">Asel Tursun</p>
        <p class="text-xs md:text-lg">Founder, Researcher</p>
      </div>
    </div>
  </div>-->

  <!-- Testimonials2 -->
<!--  <div class="max-w-[1320px] w-full mx-auto px-4 py-12 md:py-24">
    <p class="text-2xl md:text-3xl mb-8">Board of advisors</p>

    <div class="grid grid-cols-2 md:grid-cols-3 md:flex gap-4 md:gap-6 text-center">
      <div>
        <div class="md:w-52 h-52 bg-gray-300 mb-3"></div>
        <p class="mb-1 md:text-2xl font-medium">KC McCreery</p>
        <p class="text-xs md:text-lg">FP Solutions</p>
      </div>

      <div>
        <div class="md:w-52 h-52 bg-gray-300 mb-3"></div>
        <p class="mb-1 md:text-2xl font-medium">Lewis Hong</p>
        <p class="text-xs md:text-lg">FP Solutions</p>
      </div>

      <div>
        <div class="md:w-52 h-52 bg-gray-300 mb-3"></div>
        <p class="mb-1 md:text-2xl font-medium">Shaoshuai Mou</p>
        <p class="text-xs md:text-lg">FP Solutions</p>
      </div>
    </div>
  </div>-->

  <!-- Last -->
  <last/>
</template>

<script>
import Last from "@/components/Last.vue";

export default {
  name: 'CompanyView',
  components: {Last},
  data: () => ({
    specs: [
      {
        name: 'Aircraft',
        data: [
          {
            name: 'Weight',
            value: '62 kg'
          },
          {
            name: 'Max Takeoff Weight',
            value: '85 kg'
          },
          {
            name: 'Max Diagonal Wheelbase',
            value: '2000 mm'
          },
          {
            name: 'Dimensions (L x W x H)',
            value: '2600 x 3080 x 860'
          },
          {
            name: 'Max Flight Distance',
            value: '25 km'
          },
          {
            name: 'Max Flight Time',
            value: '20 mins'
          },
        ],
        isVisible: false
      },
      {
        name: 'Propulsion System',
        data: [],
        isVisible: false
      },
      {
        name: 'Delivery Payload',
        data: [],
        isVisible: false
      },
      {
        name: 'Features',
        data: [],
        isVisible: false
      }
    ]
  }),
  methods: {
    openItem(index) {
      this.specs.map((x, idx) => {
        x.isVisible = index === idx;
      })
    }
  }
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductsView from "@/views/ProductsView.vue";
import CompanyView from "@/views/CompanyView.vue";
import TechnologyView from "@/views/TechnologyView.vue";
import SolutionsView from "@/views/SolutionsView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: SolutionsView
  },
  {
    path: '/company',
    name: 'company',
    component: CompanyView
  },
  {
    path: '/technology',
    name: 'technology',
    component: TechnologyView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

export default router

import { createStore } from 'vuex'

export default createStore({
  state: {
    showContactForm: false
  },
  getters: {
    contactForm: state => state.showContactForm
  },
  mutations: {
    SHOW_CONTACT_FORM(state, val){
      state.showContactForm = val
    },
  },
  actions: {
    showContactForm({commit}, val) {
      commit('SHOW_CONTACT_FORM', val)
    }
  }
})

<script>
  export default {
    name: 'Last'
  }
</script>

<template>
  <div class="max-w-[1320px] mx-auto px-4 py-12 md:py-24 flex justify-center items-center">
    <div class="max-w-md">
      <p class="text-brand-gray-secondary5 text-2xl md:text-3xl text-center mb-6">Join our waitlist, discover
        the full benefits of <span class="text-brand-green">ARC delivery.</span>
      </p>

      <div class="max-w-md w-full">
        <div class="relative flex w-full mb-4">
          <button
              class="!absolute right-1 top-1 z-10 select-none
              rounded bg-brand-green py-2 px-4 text-center align-middle text-white
              shadow-md shadow-brand-green-500/20 transition-all hover:shadow-lg hover:brand-green-500/40
              focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none
              peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500
              peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none"
              type="button"
              data-ripple-light="true"
          >
            Get started
          </button>
          <input
              type="email"
              class="peer h-12 w-full rounded-[7px] border border-blue-gray-200
              bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700
              outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200
              placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-brand-green
              focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder="Enter your work email"
              required
          />
        </div>
      </div>
    </div>
  </div>
</template>
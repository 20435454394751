<template>
  <div class="font-primary text-brand-black relative">
    <navbar></navbar>
    <router-view></router-view>
    <footer-section></footer-section>
    <contact-form/>
  </div>
</template>

<script>
import HomeView from "@/views/HomeView.vue";
import Navbar from "@/components/Navbar.vue";
import FooterSection from "@/components/FooterSection.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "App",
  components: {ContactForm, FooterSection, Navbar, HomeView }
};
</script>
